import React from 'react';

import grafischImage1 from 'assets/images/pages/diensten_grafischdesign/1.jpg';
import grafischImage2 from 'assets/images/pages/diensten_grafischdesign/2.jpg';
import grafischImage3 from 'assets/images/pages/diensten_grafischdesign/3.jpg';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import Content from 'blocks/Content';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Quote from 'components/Quote';

const DienstenOntwerpGrafish = () => (
  <Layout>
    <Seo image={{ src: grafischImage1 }} />

    <Title
      image={{ src: grafischImage1 }}
      alt="Graphic designer voor Magento webshop"
      subtitle="Jouw merk perfect in beeld"
      title="Graphic designer voor Magento webshop"
    >
      Op zoek naar een graphic designer voor jouw Magento webshop? Look no
      further! Wij zijn jouw full service e-commerce partner.
    </Title>

    <Section>
      <Image
        image={{ src: grafischImage2 }}
        alt="Jouw full service e-commerce partner"
      >
        <Heading size="xl" as="h2">
          Jouw full service e-commerce partner
        </Heading>

        <Text>
          Wij zijn jouw full service e-commerce partner en kijken op het gebied
          van ontwerp verder dan alleen het front-end design van een webshop.
          Desgevraagd denken wij mee bij het ontwerpen van logo’s, ontwerp,
          huisstijl en eigenlijk iedere andere vorm van beeldmateriaal dat op
          jouw website of in jouw online marketing het web opgeslingerd wordt.
        </Text>
      </Image>
    </Section>

    <Section>
      <Content wide>
        <Quote author="Mark Bodde" position="Ontwerper Batao">
          “De beelden op een webshop moeten uiteraard bijdragen aan conversie,
          maar ook passen bij wat een organisatie wil uitstralen. Wij gaan
          altijd zeer uitvoerig in gesprek met een opdrachtgever voordat we aan
          de slag gaan. Ik als graphic designer móet het bedrijf, de
          bedrijfscultuur en de waarden van een organisatie kennen, snappen en
          ademen. Anders ben je nooit in staat om het beeld écht op de juiste
          manier te laten spreken.”
        </Quote>
      </Content>
    </Section>

    <Section>
      <Image
        image={{ src: grafischImage3 }}
        alt="Altijd een graphic designer die meedenkt"
        position="right"
      >
        <Heading size="xl" as="h2">
          Altijd een graphic designer die meedenkt
        </Heading>

        <Text>
          Dat betekent natuurlijk niet dat we geen mening hebben en niet
          meedenken over het graphic design. We zien onszelf dankzij onze
          ervaring en kennis als een waardevolle sparringpartner en zijn erop
          gebrand om jouw webshop naar grote hoogten te sturen. Daarbij sparren
          we ook onderling, want bij ieder project worden naast designers ook
          developers en marketeers betrokken, wat keer op keer leidt tot
          kwalitatief goede webshops waarbij over ieder element is nagedacht.
        </Text>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default DienstenOntwerpGrafish;
